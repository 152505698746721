.playlist {
    @media screen and (min-width: 991px) {
         margin-left: 100px;
    }

    @media screen and (max-width:690px) {
        margin-left: auto;
        max-width: 450px;
        margin-right: auto;
    }
    
    margin-left: auto; // 62px;
    margin-top: 37px;
    position: relative;
    z-index: 2;
    min-width: 0px; // 252px;
    width: 100%;

    h2 {
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 13px;
        color: #fff;
        text-transform: uppercase;
        text-shadow: 1px 1px 4px rgb(0, 0, 0);
    }

    &__songs {
        margin-bottom: 38px;

        .song {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--history {
            max-height: 470px;
            overflow-y: auto; // scroll;
            scrollbar-width: thin;
            scrollbar-color: #000 rgb(81, 84, 94);

            &::-webkit-scrollbar {
                width: 5px;
                border: 1px solid #000;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: rgba(255, 255, 255, 0.2); // #f1f1f1;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #000;
            }
        }
    }
}
