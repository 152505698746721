.radio {
    width: 100%;
    max-width: 585px;
    position: relative;
    
    @media screen and (max-width:690px) {
        margin-left: auto;
        margin-right: auto;
    }

    &__machine {
        @media screen and (max-width:690px) {
            margin-left: auto;
            margin-right: auto;
            height: 370px;
            max-width: 461px;
        }

        display: flex;
        flex-flow: column;
        width: 100%;
        max-width: 299px;
        // min-width: 288px;
        height: 635px;
        position: relative;
        z-index: 10;
        background: rgba(0, 0, 0, 0.65);

        &__logo {
            height: 60px;
            width: 100%;
            max-width: 350px;
            padding: 0 1em;
            margin: 37px auto 15px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../../assets/img-nula-radio-logo.png');
        }

        &__controls {
            @media screen and (max-width:690px) {
                margin-top: 30px;
            }

            margin-top: 128px;
            display: flex;
            justify-content: space-evenly;
        }

        &__stop {
            cursor: pointer;
            width: 62px;
            height: 62px;
            background-image: url('../../assets/img-player-stop.png');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;

            &:after {
                font-family: 'Montserrat', sans-serif;
                content: "STOP";
                position: absolute;
                bottom: -27px;
                color: #fff;
                width: 100%;
                text-align: center;
                font-size: 10px;
            }
        }

        &__next {
            cursor: pointer;
            width: 62px;
            height: 62px;
            background-image: url('../../assets/img-player-next-channel.png');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: -11px;
            position: relative;

            &:after {
                font-family: 'Montserrat', sans-serif;
                content: "NEXT CHANNEL";
                position: absolute;
                bottom: -27px;
                color: #fff;
                width: 95px;
                text-align: center;
                font-size: 10px;
                left: -15px;    
            }
        }
        
        &__play {
            cursor: pointer;
            width: 154px;
            height: 154px;
            background-image: url('../../assets/img-player-play.png');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
        }

        &__volume {
            @media screen and (max-width:690px) {
                margin: 80px auto 0;
            }

            margin: 100px auto 0;
            position: relative;
            width: 80%;
            overflow: hidden;
            height: 35px;
            padding: 5px 2px 0;

            input {
                width: 100%;
                border: none;
                background: none;
                position: relative;
                z-index: 9;
            }

            input[type='range'] {
                /*removes default webkit styles*/
                -webkit-appearance: none;
                /*fix for FF unable to apply focus style bug */
                border: 0;
                /*required for proper track sizing in FF*/
                width: 100%;
                outline: 0;
                height: 20px;
                /*hide the outline behind the border*/
            }

            input[type='range']:focus {
                outline: none;
            }

            input[type='range']:focus::-webkit-slider-runnable-track {
                  background: transparent;
            }

            input[type='range']::-moz-focus-outer {
                  border: 0;
            }

            input[type='range']::-moz-focus-inner {
                  border: none;
            }

            input[type='range']::-moz-range-track {
                width: 300px;
                height: 5px;
                background: transparent;
                border: none;
                border-radius: 3px;
            }

            input[type='range']::-moz-range-thumb {
                border: none;
                height: 28px;
                width: 15px;
                border-radius: 3px;
                /* background: #c30; */
                margin-top: -13px;
                background: #d5d2ca;
                background: linear-gradient(
                    to bottom,
                    #d5d2ca 0%,
                    #e0dcd5 50%,
                    #d5d1ca 50%,
                    #e0ddd5 100%
                );
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
            }

            input[type='range']::-webkit-slider-runnable-track {
                position: relative;
                z-index: 2;
                width: 200px;
                height: 5px;
            }

            input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                border: none;
                height: 28px;
                width: 15px;
                border-radius: 3px;
                /* background: #c30; */
                margin-top: -13px;
                background: #d5d2ca;
                background: linear-gradient(
                    to bottom,
                    #d5d2ca 0%,
                    #e0dcd5 50%,
                    #d5d1ca 50%,
                    #e0ddd5 100%
                );
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
            }
        } 

        &__volume-line {
            width: 100%;
            top: 7.5px;
            position: absolute;
            z-index: 1;
            border-style: solid;
            border-width: 0px 0px 15px 370px;
            border-color: transparent transparent #e0dcd5 transparent;
        }
    }

    &__record {
        @media screen and (min-width: 691px) {
            left: 50px; // 88px;
        }

        @media screen and (max-width:690px) {
            position: relative;
            margin-top: -265px;
            margin-bottom: 38px;
            max-width: 461px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 2em;
        }

        @media screen and (max-width:450px) {
            margin-top: -225px;
        }

        @media screen and (max-width:375px) {
            margin-top: -205px;
        }
        
        @media screen and (max-width:330px) {
            margin-top: -177px;
        }

        @media screen and (max-width: 290px) {
            margin-top: -157px;
        }
        //width: 100%;
        //height: 100%;
        width: 100%; //497px;
        //height: 497px;
        position: absolute;
        top: 64px;
        //left: 88px;
    }
    
    &__record-vinyl {
        @media screen and (max-width:690px) {
            width: 100%;
            height: 100%;
            margin-right: auto;
            padding-top: 100%;
        }

        width: 498px;
        height: 498px;
        margin-left: auto;
        background-image: url('../../assets/img-record.png');
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        background-color: #000;
        border-radius: 100%;
        display: flex;
    }

    &__record-rotate {
         animation-name: rotate;
         animation-duration: 4000ms;
         animation-delay: 0;
         animation-iteration-count: infinite;
         animation-timing-function: linear;

        @keyframes rotate {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(359deg);
            }
        }
    }

    &__record-cover {
        width: 54%;
        height: 54%;
        border-radius: 100%;
        margin: auto;
        background-size: cover;

        @media screen and (max-width:690px) {
            margin: -76% auto;
            padding-top: 54%;
        }
    }

    &__record-circle {
        @media screen and (max-width:690px) {
            width: 20px;
            height: 20px;
            top: calc(50% - 10px);
            left: calc(50% - 10px);
        }

        @media screen and (max-width:450px) {
            width: 18px;
            height: 18px;
            top: calc(50% - 9px);
            left: calc(50% - 9px);
        }
        
        @media screen and (max-width:375px) {
            //width: 20px;
            //height: 20px;
            //top: calc(50% - 10px);
            //left: calc(50% - 10px);
        }

        width: 26px;
        height: 26px;
        top: calc(50% - 13px);
        left: calc(50% - 13px);
        position: absolute;
        z-index: 5;
        border-radius: 13px;
        background-color: black;
        border: 1px solid gray;
    }
}
