.donation-img {
    width: 260px;
    align-self: flex-end;
    margin: 1em auto 0;
    display: block;
}

.donation-header {
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.donation-content {
    background: #FFF;
    margin: 0 auto 1em;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    border-radius: 0 0 7px 7px;

    p {
        font-family: 'Source Sans Pro', sans-serif;
        margin-top: 0;
        margin-bottom: 1.5em;
    }

    a {
        color: #000;
    }

    @media only screen and (min-width: 650px) {
        padding: 30px;
    }
}

.donation-paypal-btn {
    width: 200px;
    height: 50px;
    background-image: url('../assets/paypal.png');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center center;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    margin: 2em auto;
}

.thank-you-content {
    margin: 1em auto;

    p {
        font-family: 'Source Sans Pro', sans-serif;
        color: #fff;
        text-align: center;
        margin: 0 0 1em;
    }
}
.thank-you-image {
    width: 300px;
    margin: 3em auto 2em;
    display: block;
}