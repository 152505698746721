a.donation-link {
    text-decoration: none;
}

.donation-container {
    width: 100%;
    border-top: 1px solid #51545e;
    border-bottom: 1px solid #51545e;
    display: table;
    padding: 0 30px;
    // background: #343843;
    overflow: hidden;
    display: flex;
    text-decoration: none;

    animation-delay: 5s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    -webkit-animation-name: slideDown;
    animation-name: slideDown;

    // &:hover {
    //     background: #2c303a;
    // }

    @media only screen and (min-width: 465px) {

        @keyframes slideDown {
            from {
                height: 0;
            }
            to {
                height: 138px;
            }
        }
    }

    @media only screen and (min-width: 650px) {

        @keyframes slideDown {
            from {
                height: 0;
            }
            to {
                height: 152px;
            }
        }
    }

    &__inner {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
    }

    &__banner {
        width: 100%;
        max-width: 1000px;
        float: right;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-left: auto;

        @media only screen and (max-width: 650px) {
            flex-flow: column-reverse; //row nowrap;
        }
    }

    &__banner-image {
        width: 160px;

        @media only screen and (min-width: 465px) {
            align-self: flex-end;
        }

        @media only screen and (min-width: 650px) {
            width: 333px;
        }

        @media only screen and (max-width: 650px) {
            align-self: center;
        }
    }

    &__content {
        text-align: center;
        margin: 0.5em 0;
        width: 100%;
        max-width: 100%;

        p {
            color: #fff;
            font-family: 'Roboto', sans-serif;
            font-size: 0.8em;
        }

        a {
            text-decoration: none;
        }

        &__title {
            color: #fff;
            font-family: 'Roboto Slab', sans-serif;
            font-size: 0.9em;
            line-height: 1.3em;

            @media only screen and (min-width: 650px) {
                font-size: 1em;
            }
        }

        &__cta {
            color: #fff;
            font-family: 'Roboto Slab', sans-serif;
            padding: 0.7em 2em 0.65em;
            border: 1px solid #fff;
            display: table;
            margin: 1em auto;
            font-size: 0.9em;
            text-transform: uppercase;
            border-radius: 3px;
            cursor: pointer;

            &:hover {
                border-color: #343843;
                color: #fff;
            }

            @media only screen and (min-width: 650px) {
                font-size: 1.1em;
            }
        }
    }
}

@keyframes slideDown {
    from {
        height: 0;
    }
    to {
        height: 243px;
        // height: 220px;
    }
}