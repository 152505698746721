.stations {
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgb(255 255 255 / 28%);
    flex-flow: column;
    margin-bottom: 37px;

    @media screen and (max-width:690px) {
        max-width: 585px;
        padding: 30px 30px 0;
        margin: 0 auto;
        border-bottom: 0;
        margin-bottom: 30px;
    }

    &__topbar {
        display: none;
        background: rgba(0, 0, 0, 0.65);
        color: #fff;
        text-align: center;
        padding: 1em 0 1em;
        align-items: center;

        @media screen and (max-width:690px) {
            display: flex;           
            width: 100%;
            max-width: 461px;
            margin: 0 auto;
        }
        
        &__name {
            font-family: 'Montserrat', sans-serif;
            font-size: 21px;
            text-transform: uppercase;
            margin-bottom: 13px;
        }

        &__desc {
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 0;
        }
    }

    &__topbar-menu {
        cursor: pointer;
        display: inline-block;
        padding: 28px 20px;
        position: relative;
        user-select: none;
        margin-left: auto;

        .navicon {
            background: #fff;
            display: block;
            height: 1px;
            position: relative;
            transition: background .2s ease-out;
            width: 28px;

            &:before, &:after {
                background: #fff;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                transition: all .2s ease-out;
                width: 100%;
            }

            &:before {
                top: 10px;
            }

            &:after {
                top: -10px;
            }

            .open & {
                background: transparent;

                &:before {
                    transform: rotate(-45deg);
                    top: 0;
                }
                &:after {
                   transform: rotate(45deg);
                   top: 0;
                }
            }
        }
    }

    &__topbar-selected {
        text-align: left;
        min-width: 100px;
        padding-left: 1em;
    }

    &__container {
        display: flex;
        width: 100%;
        max-width: 1202px;
        margin: auto;
        justify-content: space-between;
        
        @media screen and (max-width:690px) {
            overflow: hidden;
            max-height: 0;
            flex-flow: column;
            transition: max-height .15s ease-out;
            background: rgba(0, 0, 0, 0.65);
            max-width: 461px;
            margin: 0 auto;
        }

        .open & {
            max-height: 337px;
            border-top: 1px solid rgb(255, 255, 255, 23%);
        }
    }

    &__item {
        cursor: pointer;
        text-align: center;
        height: 84px;
        display: flex;
        flex-flow: column;
        padding: 0 1.5em;
        place-content: center;
        transition: 0.5s all ease;
        border-bottom: 3px solid transparent;
        text-decoration: none;

        @media screen and (max-width:690px) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
        }

        &__name {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            color: #fff;
            margin-bottom: 6px;
            text-transform: uppercase;
            text-shadow: 1px 1px 4px black;
        }

        &__desc {
            font-size: 13px;
            color: #fff;
            text-shadow: 1px 1px 4px black;
        }

        &--selected {
            border-bottom: 3px solid #fff;
            
            @media screen and (max-width:690px) {
                border-bottom: 0;       
            }
        }

        &:hover {
            border-bottom: 3px solid #fff; 
            
            @media screen and (max-width:690px) {
                border-bottom: 0;
            } 
        }
    }
}
