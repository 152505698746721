html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

html {
    box-sizing: border-box;
}

    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
      margin: 0;
      font-family: 'Roboto', sans-serif;
      -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #222325;
}

a:link    {color: rgb(177, 178, 178);}
a:visited {color: rgb(177, 178, 178);}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root { 
    width: inherit;
    height: inherit;
}

.hero {
    width: 100%;
    min-height: 794px;
    // background-image: url('assets/img-hero-desktop-01-classic.jpg');
    background-size: cover;
    background-position: top;
    transition: 0.15s all ease-in-out;
    // margin-bottom: 30px;
    
    @media screen and (max-width: 690px) {
        margin-bottom: 0; 
    }

    &--classic {
        background-image: url('assets/img-hero-desktop-01-classic.jpg');

        @media screen and (max-width: 690px) {
             background-image: url('assets/img-hero-mobile-01-classic.jpg');
        }
    }

    &--organic {
        background-image: url('assets/img-hero-desktop-02-organic.jpg');
        
        @media screen and (max-width: 690px) {
             background-image: url('assets/img-hero-mobile-02-organic.jpg');
        }
    }

    &--beatz {
        background-image: url('assets/img-hero-desktop-03-beatz.jpg');
        
        @media screen and (max-width: 690px) {
             background-image: url('assets/img-hero-mobile-03-beatz.jpg');
        }
    }

    &--bassic {
        background-image: url('assets/img-hero-desktop-04-bassic.jpg');
        
        @media screen and (max-width: 690px) {
             background-image: url('assets/img-hero-mobile-04-bassic.jpg');
        }
    }
}

.container {
    @media screen and (max-width:690px) {
        justify-content: center;
        flex-flow: column;
    }

    width: 100%;
    max-width: 1202px;
    margin: auto;
    display: flex;
    padding: 0 30px;
}

.facebook-wrapper {
    background: #fff;
    max-width: 584px; 
    width: 100%;
    margin-top: 30px;
    
    @media screen and (max-width: 1190px) {
        max-width: 50%;
    }

    @media screen and (max-width: 690px) {
        max-width: 100%;
    }
}

.imgs-wrapper {
    width: 50%;
    margin-left: 30px;

    @media screen and (max-width: 1190px) {
         width: 50%;
     }

     @media screen and (max-width: 690px) {
         width: 100%;
         margin-left: 0;
         //margin-top: 30px;
     }
}
