.song {
    display: flex;
    align-items: center;
    position: relative;

    &__name {
        font-size: 15px;
        color: #fff;
        text-shadow: 1px 1px 2px rgb(0, 0, 0);
        //flex: 1 0 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-basis: 90%;
    }

    &__cover {
        width: 32px;
        height: 32px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 0.5em;
        transition: background-image 0.15s ease-in-out;
    }

    &__time {
        positon: absolute;
        top: 5px;
        right: 5px;
    }
}
