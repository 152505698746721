.img {
    width: 100%;

    img {
        max-width: 100%;
        width: 100%;
        display: block;
        margin-bottom: 30px;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease-in-out;
        
        &:hover {
            transform: scale(1.01);
        }
    }

    img.no-borders {
        box-shadow: none;
        border-radius: 0;
    }
}
